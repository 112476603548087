import axios from 'axios';

export const getTokenFromCloudFunction = async () => {
  const cloudFunctionUrl = 'https://us-central1-ford-bd9edc5daa2114e9d96394a3.cloudfunctions.net/get_token_cf'; // replace with your Google Cloud Function URL

  try {
    const response = await axios.get(cloudFunctionUrl);
    return response.data.token; // this will be the token
  } catch (error) {
    console.error('Error getting token from Google Cloud Function', error);
    return null;
  }
};