import React from 'react';
import './welcome.css'

const SimpleComponent = () => {
  return (
    <div className='welcome-wrapper'>
      <h1>
        Welcome to the <strong>SmartFMA</strong> tool
      </h1>
      <p>
      SmartFMA is a powerful tool designed to streamline the creation of Model Based Failure Mode Avoidance (MBFMA) models for MagicDraw using the Part Address Database (<a href="https://www.padb.ford.com" target="_blank" rel="noopener noreferrer">PADB</a>) framework. 
      With SmartFMA, you can easily generate functions by selecting a Corporate Product Systems Classification (CPSC) and its sub-components, all while harnessing the power of Artificial Intelligence (AI).
      </p>
      <h4>Here's how SmartFMA works:</h4>
      <ol>
        <li>Select a CPSC and its sub-components to generate functions.</li>
        <li>Let SmartFMA guide you through the process with its intuitive interface.</li>
        <li>SmartFMA will automatically generate Inputs and Outputs for your selected functions.</li>
      </ol>
      <p>With SmartFMA, creating detailed FMA models has never been easier. Start streamlining your MBFMA workflow today with SmartFMA!</p>
      
      <p className="selectText">Please select one or more systems from the dropdown below.</p>

      <i className="selectTexti" >Please note that responses from SmartFMA may vary due to the inherent randomness in large language models. Even with the same input, different responses may be generated</i>
      
    </div>
  );
};

export default SimpleComponent;