import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';

function Profile() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  if (!account) {
    instance.loginRedirect();
    return null;
  }

  return <div>Welcome, {account.name}!</div>;
}

export default Profile;
