import './terms.css';

import React, { useState, useRef, useEffect,useMemo } from "react";

export default function TermsPopup() {
	const agreedStamp = window.localStorage.getItem("agreedToTermsTimestamp") ? new Date(window.localStorage.getItem("agreedToTermsTimestamp")) : new Date(0);
  const now = new Date();
  const diffDays = (now.getTime() - agreedStamp.getTime()) / (1000 * 60 * 60 * 24);
  // If the user has agreed to the terms within the last 7 days, don't render the component
  if (diffDays < 7) return null;

	const [pageIndex, setPageIndex] = useState(0);
	const pages = useMemo(() => [ PageTwo, PageThree]);
	const dialogRef = useRef(null);

	useEffect(() => {
		//const now = new Date();
		//const diffDays = (now.getTime() - agreedStamp.getTime()) / (1000 * 60 * 60 * 24);

		//if (diffDays < 7) return;

		const dialog = dialogRef.current;
		dialog.showModal();
		return () => dialog.close();
	}, []);

	useEffect(() => {
		if (pageIndex >= pages.length) {
			const dialog = dialogRef.current;
			// TODO: We probably don't want to store this on the client
			window.localStorage.setItem("agreedToTermsTimestamp", new Date().toString());
			dialog?.close();
		}
	}, [pageIndex]);

	if (pageIndex >= pages.length) return;

	return (
		<dialog ref={dialogRef} className="popup  border-0 shadow block" style={{ maxWidth: "38em" }}>
			{pages.map((Page, i) => {
				if (pageIndex != i) return;

				return <Page key={i} onNext={() => setPageIndex(pageIndex + 1)} onBack={() => setPageIndex(pageIndex - 1)} />;
			})}
		</dialog>
	);
}

// const PageOne = ({ onNext }) => {
// 	return (
//     <div className="parent">
// 		<div className="popup" style={{ width: 'auto', height: 'auto', padding: '20px', margin: '20px 5px 20px 30px' }}>
//       <div className='wrapper'>
// 			<h5 >Welcome to SmartFMA!</h5>
// 			<p> SmartFMA is an artificial intelligence tool to help generate functions and associated inputs/outputs.</p>
// 			<br/>
//       <p>SmartFMA is powered by large language models from OpenAl and Google.</p>
//       <br/>
// 			<div className=" gx-2 banner-small-top">
				
// 					<button onClick={onNext} className="next-button">
// 						Next
// 					</button>
          
// 				</div>
// 			</div>
// 		</div>
//     </div>
// 	);
// };

const PageTwo = ({ onNext, onBack }) => {
	const [agreeSecret, setAgreeSecret] = useState(false);
	const [agreeVerify, setAgreeVerify] = useState(false);

	return (
    <div className='parent'>
    <div className="popup" >
		
			<h5 className="heading-h4-semibold">Usage Agreement </h5>
      
      <div className="banner-bottom">
    
        {/* <p className="icon-group">
        
          <span className="large icon-group__icon">🔬</span>
          <span className="text-base-medium ">Carefully verify that each function/input/output generated and selected matches the exact intent of your FMA.</span>
        </p> */}
       
        <label className="checkbox-group" >
          <input type="checkbox" value={agreeVerify} onChange={(e) => setAgreeVerify(e.target.checked)} />
          <strong className="text-base-bold" >I agree to carefully verify each SmartFMA function, input, output generated and selected matches the exact intent for your FMA</strong>
        </label>
			</div>
				<p className="icon-group">
					<span className="large icon-group__icon">⚠️</span>
					<span className="text-base-medium ">Note: DFMEA/FMA Models are Ford proprietary and confidential.</span>
				</p>
       <br/>
		
				<div className="col-auto">
        <button onClick={onNext} className={`next-button-pagetwo ${!agreeVerify ? 'disabled' : 'enabled'}`} disabled={!agreeVerify}>
  Next
</button>
				
				
				</div>
			
        </div>
        </div>
	);
};

const PageThree = ({ onNext, onBack }) => {
	return (
    <div className='parent'>
		<div className="popup" >
			<h5 className="heading-h4-semibold">Let us know what you think</h5>
			<p className="icon-group">
				<span className="large icon-group__icon">👍</span>
				<span class="text-base-medium ">Let us know if a particular response was good or unhelpful.</span>
			</p>
			<p className="icon-group">
				<span className="large icon-group__icon">🔬</span>
				<span className="text-base-medium ">Your Final Selections/Exports may be reviewed by data scientists to improve our systems.</span>
			</p>
			<p className="icon-group">
				<span className="large icon-group__icon">💬</span>
				<span className="text-base-medium ">
					Share your feedback in our <a href="https://www.webexteams.ford.com/space?r=81lt">Webex Teams Space</a>.
				</span>
			</p>
			<div className="row gx-2 banner-medium-top">
				<div className="col-auto">
					<button onClick={onBack} className="back-button">
						Back
					</button>
				</div>
				<div className="col-auto">
					<button onClick={onNext} className="done-button">
						Done
					</button>
				</div>
			</div>
		</div>
    </div>
	);
};