import React, { useState, useEffect } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './filter.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

export default function FilterDemo({ token, user, sessionStart, setShowData, setResultsData, SetFunctions, SetOldFunctions}) {
    const [selectedSystems, setSelectedSystems] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);
    const [serverMessage, setServerMessage] = useState(''); // New state variable for server message
    const [systems, setSystems] = useState([]); // New state variable for systems

     // New state variable for old functions

    // const timesIcon = <button className="pi pi-times blue-color" rounded text aria-label="Filter" />;
    const timesIcon = <Button icon="pi pi-thumbs-up" rounded text aria-label="Filter" />
    const renderFooter = (name) => {
        return (
            <div>
                <Button className='rounded-button' label="Close" icon="pi pi-times" onClick={() => setDisplayModal(false)} />
            </div>
        );
    }
    

    useEffect(() => {
        if(token){
            fetch(process.env.REACT_APP_FLASK_URL + '/systems', {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            })
            .then(response => response.json())
            .then(data => {
                          setSystems(data);
            }) // Set systems to the response data
            .catch(error => console.error('Error:', error));
    
            handleSave();
        }
        
    }, [token]);

    const handleSave = () => {
        const feedbackData = {
            CDSID: user,
            sessionStart: sessionStart.toISOString(),
            
          }; 
          
        const config = {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        }
      
          axios.post(process.env.REACT_APP_FLASK_URL + '/endSession', feedbackData, config)
            .then(response => {
          })
      }
    
    
    const handleSubmit = () => {
        setDisplayModal(true)
        const forceFunctionCheckbox = document.querySelector('.ForceFunctionsCheck');
        const isChecked = forceFunctionCheckbox.checked;
        // Parse the selectedSystems value to the endpoint /selection
        fetch(process.env.REACT_APP_FLASK_URL + '/selection', {
            method: 'POST',
            body: JSON.stringify({
                selectedSystems: selectedSystems,
                CDSID: user,
                ForceFunctionCheck: isChecked
            }),
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        .then(data => {
            // Handle the response from the endpoint if needed
            setDisplayModal(data.Functions); // Update the modal value with data.message
            setResultsData(data.Functions);
            SetFunctions(selectedSystems);
            SetOldFunctions(data.data);
            setShowData(true);
        })
        .catch(error => {
            // Handle any errors that occur during the request
            console.error(error);
        });
    };

    return (
        <div className="stacked-card">
        <div className="card flex justify-content-center">
            <Dialog header="Processing..." visible={displayModal} style={{ width: '50vw' }} footer={renderFooter('displayBasic')} onHide={() => setDisplayModal(false)}>
            <p>{serverMessage ? serverMessage : 'Connecting to GPT4 and parsing selected Systems'}</p> 
            <p>Please wait.</p> 
            <ProgressSpinner  strokeWidth="8" />
            <p>Please note the LLM can take upto 2-3 minutes to respond if you select a group of 4 to 5 systems</p>
            </Dialog>
            <MultiSelect value={selectedSystems} onChange={(e) => setSelectedSystems(e.value)} options={systems} display="chip" optionLabel="name" 
                filter placeholder="Select Systems" clearIcon={timesIcon} className="w-full md:w-20rem" />
            <Button className='rounded-button' disabled={!selectedSystems} label="Submit" onClick={handleSubmit} />
        </div>
            <div className="forcefunctions">
                <input className="ForceFunctionsCheck" id="forceFunctionsCheckbox"  type="checkbox" />
                <label htmlFor="forceFunctionsCheckbox">Force re-generation of functions through LLM.</label>
            </div>
        </div>
    );
}
